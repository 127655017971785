.ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-select-multiple .ant-select-selector, .ant-select .ant-select-selector {
    background-color: #fff !important;
    box-shadow: none;
    border: #ebebeb 1px solid;
    border-radius: 20px;
    display: flex;
    flex: 1 1;
    color: #1f2022;
    line-height: 1.42857;
    transition: background 0.2s ease-in-out, border 0.2s ease-in-out, box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
    will-change: background, border, box-shadow, color;
    width: 100%;
    padding: 0 !important;
}

.ant-form-item-label > label {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    height: 32px;
    color: #1C3A6A;
    font-size: 14px;
    font-weight: 700;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
    background:#fff !important;
}

.ant-table-wrapper .ant-table tr td {
    border: 0;
    border-bottom: 2px dotted #ccc !important;
    border-radius: 0 !important;
    padding: 0.71429rem;
    line-height: 20px;
    font-weight: 400;
}

b, strong {
    font-weight: 400 !important;
    font-size: 12px !important;
}

.ant-table-wrapper .ant-table-thead tr > th {
    background-color: #fff !important;
    color: #1C3A6A !important;
    border-bottom: 2px dotted #ccc !important;
    font-weight: 700;
    position: relative;
    height: 40px;
}
.ant-table-wrapper .ant-table-thead tr > th {
    background-color: rgba(31, 32, 34, 0.1);
    color: #1f2022;
    font-weight: 700;
    position: relative;
    height: 40px;
}

.ant-input {
    background-color: #fff;
    box-shadow: none;
    border: #ebebeb 1px solid;
    border-radius: 20px;
    display: flex;
    flex: 1 1;
    color: #1f2022;
    line-height: 1.42857;
    transition: background 0.2s ease-in-out, border 0.2s ease-in-out, box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
    will-change: background, border, box-shadow, color;
    width: 100%;
}
.ant-card-body {
    padding: 0.42857rem !important;
}

.ant-table-wrapper.accent-header .ant-table .ant-table-thead tr > th {
    background-color: #ccc !important;
    color: #000 !important;
}
/* .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    color: white !important;
   } */
   .ant-pagination .ant-pagination-item.ant-pagination-item-active a {
    background-color: green !important;
 }
 .triangle{
    border-top: 60px solid #fff;
      border-right: 65px solid transparent;
      height: 0;
      margin-left:"-10px";
      float:left;
  }
  /* .ant-popover-placement-top, .ant-popover-placement-topLeft, .ant-popover-placement-topRight{
    width:100% !important;
    left: 0 !important;
    max-height: 600px;
    overflow-y: scroll;
    
}
.ant-popover-content{
    width: 600px;
    margin: 0px auto;

} */

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;  
}
input[data-autocompleted] {
    background-color: transparent !important;
}