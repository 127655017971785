.doclink{

        background-color: white; 
        color: black; 
        border: 2px solid #4CAF50;

}

._loading_overlay_content {
        top: 45%;
        left: 48%;
        position: fixed;
      }