.ant-select-selector{
  min-height:40px !important;
  height:auto !important;
  border-radius: 50px !important;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  padding:10px !important;
}

/* .wj-flexgrid {
  min-width: fit-content;
} */
.dummyWijmoData{
  display: none !important;
}
#theGridTallRows.wj-flexgrid .wj-colheaders .wj-header{
    background-color:#425a81;
    color:#fff;
  }

  .audiogram.wj-flexgrid .wj-colheaders .wj-header{
    background-color:rgb(195,95,78) !important;
  }
  .audiogramLeft.wj-flexgrid .wj-colheaders .wj-header{
    background-color:rgb(60,107,246) !important;
  }
  .rightearresult th{
    width:180px;
  }

  .rightearcheck span{
    font-size: 14px;
    font-weight: bold;
    margin-right:30px;
  }

  .rightearcheck label{
    margin-left:10px;
  }

  .rightearcheck label input{
    margin-left:10px;
  }

  .wj-control .wj-input-group .wj-form-control{
    font-size:14px;
  }
  .wj-btn{
    font-size: 14px;
  }

  #theGridTallRows .wj-cells .wj-row:hover .wj-cell:not(.wj-state-selected):not(.wj-state-multi-selected) {
    transition: all 0.5s;

  }
  #theGridTallRows.wj-flexgrid .wj-cell {
    /* border:none; */
    font-size:14px;
    color: #5A5A5A;
    border-bottom:1px solid #D3D3D3;
    padding-top: 10px;
    padding-bottom: 10px;
    /* height:40px !important; */
    /* top:40px !important; */
  }

  #theGridTallRows.wj-flexgrid .wj-align-right{
    text-align: left;
  }


  #theGridTallRowss .wj-flexgrid:hover{
    cursor: pointer;
  }

  .clinicListTable.wj-flexgrid .wj-cell{
    text-align: left;
  }

  .clinicListTable.wj-flexgrid .wj-align-center{
    text-align: center !important;
  }

  .navbar-skeleton{
    display: none !important;
  }

  .wj-cell {
    white-space: break-spaces !important;
  }

  .driveClass .wj-cells .wj-row .wj-cell:hover{
    cursor: pointer;
  }
  
  .wj-cell.row{
    white-space: break-spaces !important;
  }

  .wj-flexgrid .wj-state-selected {
    background-color: black !important;   
}

.wj-flexgrid .wj-state-multi-selected {
  background-color: white !important;
}
  
  
  .wj-cells .wj-cell.wj-state-selected {
    background:white !important;
    color:black !important;
  }
.wj-flexgrid {
    margin: 10px 0;
}

.column-picker-icon {
    cursor: pointer;
    color: #FF8754;
    margin: 3px;
}

.column-picker-div {
    display: none;
}

.wj-listbox.column-picker {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0 10px;
    columns: 4;
    padding: 12px;
    margin-left: 12px;
    margin-top: 26px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.wj-listbox .wj-listbox-item>label {
    display: block;
    margin: 0 0 3px 0;
}

.wj-listbox .wj-listbox-item.wj-state-selected {
    background: transparent;
    color: inherit;
}

.wj-listbox .wj-listbox-item:hover {
    background: rgba(0, 0, 0, .05);
}

.wj-listbox .drop-marker {
    position: absolute;
    background: #0085c7;
    opacity: 0.5;
    pointer-events: none;
    z-index: 1000;
}



.poolGenerated .wj-cells .wj-row .wj-cell:hover{
  cursor: pointer;
}
.dashboard .wj-cells .wj-row .wj-cell:hover{
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.1) !important; 
  border-radius: 10px !important;
  border:0.5rem solid transparent !important;
}

::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-track {
  box-shadow:0 0 5px rgba(81, 89, 106,0.1) !important; 
  border-radius: 10px !important;
}
 
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.1) !important; 
  border-radius: 10px !important;
  border:0.5rem solid transparent !important;
}

#theGridTallRows ::-webkit-scrollbar {
  /* Set width and height of the scrollbar */
  height:6px !important;
  width: 6px !important;
}

#theGridTallRows ::-webkit-scrollbar-track {
  /* Set background color of the scrollbar track */
  background-color: rgba(0,0,0,0.1) !important;
}

#theGridTallRows ::-webkit-scrollbar-thumb {
  /* Set color and border radius of the scrollbar thumb */
  background-color:rgba(0,0,0,0.1)!important;
  border-radius: 4px !important;
}

#theGridTallRows ::-webkit-scrollbar-thumb:hover {
  /* Set color of the scrollbar thumb on hover */
  background-color: #777 !important;
}


.ant-table-wrapper ::-webkit-scrollbar {
  /* Set width and height of the scrollbar */
  height:6px !important;
  width: 6px !important;
}

.ant-table-wrapper ::-webkit-scrollbar-track {
  /* Set background color of the scrollbar track */
  background-color: rgba(0,0,0,0.1) !important;
}

.ant-table-wrapper ::-webkit-scrollbar-thumb {
  /* Set color and border radius of the scrollbar thumb */
  background-color:rgba(0,0,0,0.1)!important;
  border-radius: 4px !important;
}

.ant-table-wrapper ::-webkit-scrollbar-thumb:hover {
  /* Set color of the scrollbar thumb on hover */
  background-color: #777 !important;
}

.cliniclisttable .wj-cell{
  font-size: 12px !important;
}

.wj-cell .wj-glyph-plus{
  font-size: 11px !important;
}
.wj-cell .wj-glyph-minus{
  font-size: 11px !important;
}
@media screen and (max-width:1024px){
  .ant-layout{
      display: table !important;
      width: 100%;
  }

  .ant-form-item-control-input{
    min-height: 0px !important;
  }

  .ant-col-24.ant-form-item-label{
    padding: 0px 0px 0px !important;
  }
}

.ant-input-search-button{
  background-color: #3d586a !important;
}

.ant-input-search-with-button .ant-input-group .ant-input-sm{
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  background-color: white;
}

.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner{
  width: 15px !important;
  height: 15px !important;
}

.ant-input-search-with-button{
  width: 304px !important;
}
@media screen and (max-width: 1024px) {
  .ant-input-group-wrapper-sm{
    width: 220px !important;
  }
}






