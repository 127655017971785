*{
    box-sizing: border-box;
}
.container{
    padding: 0% !important;
    width: 100% !important;
    height: 100% !important;
    position: relative;
}

.img{
    filter: blur(4px);
    position: absolute;
}

.logoNew {
    display: block;
    margin: 10px auto 0; /* Decreased the top margin value */
    height: 150px; /* Adjust height as needed */
    width: auto;
  }
    .text{
    position: absolute;
    color: white;
    left: 43%;
    right: 40%;
    top: 3%;
    z-index: 1;
}

.box{
    position: absolute;
    border: 2px solid wheat;
    width: 90%;
    height: 63%;
    left: 81px;
    top: 95px;
    z-index: 1;
}

.box h5{
    text-align: center;
    color: white;
    z-index: 1;
}

ul{
    color: white;
    font-weight: 500;
    z-index: 1;
}

.btn{
    color: wheat;
    font-weight: 700 !important;
    padding: 10px 50px !important;
    background-color: rgb(255, 106, 7) ;
}

.btn:hover{
    background-color: rgb(92, 92, 227);
}
.container {
    display: flex;
    align-items: center;
    top:20px;
    flex-direction: column;
    height: 100vh;
  }
  
  .centered-content {
    text-align: center;
  }
  
  .card {
    background-color:whitesmoke;
    padding: 20px;
    border-radius: 10px;
    width: auto;
    max-width: 80%;
    margin-bottom: 20px; /* Adjust as needed */
    box-shadow: 0px 1px 11px #000;
  }

  .card ol {
    font-weight: bold;
  }
  
  @media screen and (max-width:1024px){
    .img{
        height: 100% !important;
    }

    .checkin .mt-5{
        margin-top: 50px !important;
    }
 }
  