@import '../../../assets/sass/variables';

$_module-rem: $module-rem;
$_bg: #fff;
$_shape: $shape;
$_shadow: $shadow;
$_shadow-hover: $shadow-hover;
$_animation: $animation;
$_sl-color: inherit;
$_sl-color-hover: accent-palette(500);

.contact {
  background: $_bg;
  border-radius: $_shape;
  box-shadow: $_shadow;
  display: block;
  margin: 0 auto $_module-rem * 2;
  max-width: 400px;
  text-align: center;
  transition: box-shadow 0.2s $_animation;

  &:hover {
    box-shadow: $_shadow-hover;
  }
  .img-box {
    background: rgba(#000, 0.05);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    overflow: hidden;
  }
  .info-box {
    padding: ($_module-rem * 2) ($_module-rem * 2) ($_module-rem * 3);

    .name {
      margin: 0 0 $_module-rem;
    }
    .role {
      opacity: 0.6;
    }
    .social {
      display: flex;
      justify-content: center;
      margin: 0 (-$_module-rem / 2) ($_module-rem * 2);

      .link {
        color: $_sl-color;
        display: block;
        font-size: #{$main-lh}rem;
        margin: 0 ($_module-rem / 2);

        &:hover {
          color: $_sl-color-hover;
        }
      }
    }
    .address {
      opacity: 0.6;
    }
    .button-box {
      display: flex;
      justify-content: center;
    }
  }
}
